import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "field", "error"];

  check(e) {
    if(this.fieldTarget.value == null || this.fieldTarget.value.length === 0) {
      this.errorTarget.style.display = 'block';
      e.preventDefault();
    }
  }
}
