import 'popper.js';
import 'ekko-lightbox';
import 'bootstrap';

function documentReady() {
  $(window).scroll(function () {
    if ($(this).scrollTop() >= ($('.jumbotron').height())) {
      $('body').addClass('scrolling');
    }
    else {
      $('body').removeClass('scrolling');
    }

    if ($(this).scrollTop() >= $('.navbar-default').height()) {
      $('body').addClass('scrolling-menu');
    }
    else {
      $('body').removeClass('scrolling-menu');
    }
  });

  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();

  let $screenSlider = $('#screenSlider');
  if ($('body').hasClass('screen-body') && $screenSlider.length) {
    setTimeout(function() {
      location.reload();
    }, 2 * $('.carousel-item').length*parseInt($screenSlider.data('interval')));
  }
}

$(document).on('turbolinks:load', documentReady);

$(document).delegate('*[data-toggle="lightbox"]', 'click', function (event) {
  event.preventDefault();
  $(this).ekkoLightbox();
});
