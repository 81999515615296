
$(function() {
  $("#recurring_unit").change(function() {
    const unit = $(this).val();

    return $("#recurring_frequency option").each(function() {
      if ($(this).val() === "1") {
        if (unit === 'day') {
          return $(this).text("Jeden");
        } else if (unit === 'week') {
          return $(this).text("Jede");
        } else {
          return $(this).text("Jedes");
        }
      }
    });
  });

  $("#recurring_frequency").change(function() {
    const freq = $(this).val();

    return $("#recurring_unit option").each(function() {
      if ($(this).val() === "week") {
        if (freq === '1') {
          $(this).text("Woche");
        } else {
          $(this).text("Wochen");
        }
      }
      if ($(this).val() === "day") {
        if (freq === '1') {
          $(this).text("Tag");
        } else {
          $(this).text("Tage");
        }
      }
      if ($(this).val() === "month") {
        if (freq === '1') {
          return $(this).text("Monat");
        } else {
          return $(this).text("Monate");
        }
      }
    });
  });

  $("#recurring_frequency").trigger("change");
  return $("#recurring_unit").trigger("change");
});
