
import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from 'activestorage';


Rails.start();
Turbolinks.start();
ActiveStorage.start();

import './pages.js';
import './event_form.js';
import './file_upload.js';
import './tasks.js';
import Trix from 'trix';
import "@rails/actiontext";

Trix.config.blockAttributes.heading = { tagName: 'h2' };

if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/service-worker.js').then(function (reg) {} );
}

window.addEventListener("trix-file-accept", function(event) {
  const acceptedTypes = ['image/jpeg', 'image/png', 'application/pdf']
  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault();
    alert("Es können nur jpeg, png oder pdf Files angehängt werden.");
  }
});
