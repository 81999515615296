import Sortable from 'sortablejs/modular/sortable.core.esm.js';

import { Controller } from "stimulus";

export default class extends Controller {
  updateUrl;
  sortable;
  connect() {
    this.updateUrl = this.element.dataset.updateUrl;
    this.sortable = Sortable.create(this.element, {
      ghostClass: "highlight",
      onEnd: (/**Event*/evt) => {
        const item_id = evt.item.dataset.itemId;
        const position = evt.newDraggableIndex;

        return $.ajax({
          type: 'POST',
          url: this.updateUrl,
          dataType: 'json',
          data: {id: item_id, sort_data: {row_order_position: position}}
        });
      }
    });
  }
}
