import Dropzone from 'dropzone';

Dropzone.autoDiscover = false;

function setupDropzone() {
  if ($('#media-dropzone').length > 0) {
    var theDropzone = new Dropzone("#media-dropzone", {timeout: 0});
    theDropzone.on("success", function (file, status) {
      $('#files-container').append(status.filelink);
      theDropzone.removeFile(file);
    });
  }
}

$(document).on('turbolinks:load', setupDropzone);
