
import { Controller } from "stimulus";

export default class extends Controller {
  button;
  connect() {
    this.button = this.element;
    this.button.parentElement.addEventListener('ajax:success', (event) => {
      let [data, status, xhr] = event.detail;
      if (xhr.status === 200) {
        this.button.value = data['msg'];
      }
      else {
        this.button.value = status;
      }
    });
  }
}
